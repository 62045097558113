import React from 'react';
import LogoComponent from "./LogoComponent";
import Logo from "../assets/logo.png";
import {Helmet} from "react-helmet";

function NavBar(props) {
    return (
        <nav className={'bg-white shadow flex justify-center md:justify-start items-center  sticky top-0 w-full'}>
            <Helmet>
                <link rel="preload" href={Logo} as="image"/>

            </Helmet>
            <LogoComponent/>
        </nav>
    );
}

export default NavBar;
