
import logo from '../assets/artwork.png'
import {Link} from "react-router-dom";

export default function SpinList({spins}) {
    return (
        <div id={"spins"} className={'flex justify-start md:justify-center pl-3 md:pl-0 mx-5'}>
            {
                spins.length > 0 ? <ul role="list" className="grid grid-cols-12 divide-y divide-gray-100 gap-x-6 gap-y-6 ">
                    {spins.map((spin,index) => {
                        var date = new Date(parseInt(spin.date) * 1000);
                        return (
                            <li  onClick={async () => {
                                await window.open(`/rewards?${spin.rewardURL}`,"_self");
                            }} key={index} className="cursor-pointer col-span-12 lg:col-span-6 xl:col-span-4 justify-between gap-x-6 py-5">
                                <div className="flex min-w-0 gap-x-4">
                                    <img className="h-12 w-12 object-cover rounded-full bg-gray-50" src={logo} alt=""/>
                                    <div className="min-w-0 flex-auto">
                                        <p className="text-xl font-bold leading-6 text-gray-900">{spin.title}</p>
                                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">{date.toDateString()}</p>
                                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">{spin.description}</p>
                                    </div>
                                    <p className={'text-gray-800 font-semibold  self-center'}>Redeem</p>
                                </div>
                            </li>
                        );
                    })}
                </ul> : <div className={'h-96 items-center flex justify-center justify-items-center w-full' +
                    ''}>
                    <p>No Spin Founded</p>
                </div>
            }
        </div>
    )
}
