import React, {useContext, useEffect, useState} from 'react';
import {SpinContext} from "../Provider/SpinProvider";

function Sitemap(props) {
    const spinContext = useContext(SpinContext);
    let [spins, setSpins] = useState([]);
    useEffect(() => {
        spinContext.refreshSpin().then((e) => {
            setSpins(e);
        });
    }, []);
    return <>

        {
            "<?xml version=\"1.0\" encoding=\"UTF-8\"?>" +
            "<urlset\n"+
            "xmlns='http://www.sitemaps.org/schemas/sitemap/0.9'"+
            "xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance'\n"+
            "xsi:schemaLocation='http://www.sitemaps.org/schemas/sitemap/0.9"+
            "http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd'>"

        }{
        spins.map(value => {
            let reward = "https://coinmasterfreespin.in/rewards?"+value['rewardURL'].replaceAll("&flag=1","");
            return <>
                {"<url>\n" +
                    ` <loc> ${reward}`
                    +"</loc> \n<lastmod>2024-04-21T17:30:50+00:00</lastmod>" +
                    " </url>"
                }
            </>
        })
    }
        {
            "</urlset>"
        }</>;
}

export default Sitemap;
