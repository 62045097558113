import React from 'react';
import NavBar from "../Components/NavBar";
import FooterLayout from "../Components/FooterLayout";

function PrivacyPolicy(props) {
    return (
        <div>
            <NavBar/>
            <main id={"privacy-main"} className={'px-5 2xl:px-96 '}>
                <h1>Privacy Policy</h1>
                <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data..</p>
                <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>

                <h2>Information Collection And Use</h2>
                <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

                <h2> We comply with the Children's Online Privacy Protection Act (COPPA).</h2>

                <p>Our website and mobile applications comply with the Children's Online Privacy Protection Act ("COPPA").</p>
                <p>  In the event that a user identifies himself/herself as a child under the age of 13 through a support request or feedback, we will not collect, store or use and will delete in a secure manner any personal information of such a user.</p>

                <p> We take care of filtering categories of sensitive and unsuitable ads for children. But rarely can an advertiser misclassify your ads, and this situation is beyond our reach.</p>

                <h2>  HOW WE USE AND DISCLOSE THE INFORMATION COLLECTED</h2>

                <p> We use the information collected to better understand how you use our Games, customize your experience (including the content, marketing materials, and advertisements you see), prevent abuse and improve the application.</p>

                <p> we works with the following partners to serve you third-party advertisements and develop our games:</p>

                - Google, LLC.


                For more information about our partners' practices, please visit the following links:

                - https://www.google.com/policies/privacy/partners/


                We may share the information collected only under these circumstances:

                <p> If we believe it's reasonably necessary to comply with a law, regulation or legal process.</p>
                <p> If we sell, transfer or otherwise share some or all of our assets, including the information identified in this privacy policy, in connection with a merger, acquisition, reorganization or sale of assets in the event of a bankruptcy.</p>
                <p> We may disclose aggregate information about how users use our games, always in a manner that doesn't identify you individually.</p>


                <h3>Types of Data Collected</h3>

                <h4>Personal Data</h4>
                <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
                <ul>

                    <li>Cookies and Usage Data</li>
                </ul>

                <h4>Link to privacy policy of third party service providers used by the app</h4>
                <ul>
                    <li>
                        <a href="https://www.google.com/policies/privacy/" target="_blank">Google Play Services</a></li>

                    <li><a href="https://www.facebook.com/about/privacy" target="_blank">Facebook</a></li>
                </ul>

                <h4>Usage Data</h4>
                <p>When you access the Service by or through a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data ("Usage Data").</p>

                <h4>Tracking &amp; Cookies Data</h4>
                <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
                <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
                <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                <p>Examples of Cookies we use:</p>
                <ul>
                    <li><strong>Session Cookies.</strong> We use Session Cookies to operate our Service.</li>
                    <li><strong>Preference Cookies.</strong> We use Preference Cookies to remember your preferences and various settings.</li>
                    <li><strong>Security Cookies.</strong> We use Security Cookies for security purposes.</li>
                </ul>

                <h2>Use of Data</h2>
                <p>Daily Spin: Coin master city uses the collected data for various purposes:</p>
                <ul>
                    <li>To provide and maintain the Service</li>
                    <li>To notify you about changes to our Service</li>
                    <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                    <li>To provide customer care and support</li>
                    <li>To provide analysis or valuable information so that we can improve the Service</li>
                    <li>To monitor the usage of the Service</li>
                    <li>To detect, prevent and address technical issues</li>
                </ul>

                <h2>Disclosure Of Data</h2>

                <h3>Legal Requirements</h3>
                <p>Daily Spin: Coin master city may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                <ul>
                    <li>To comply with a legal obligation</li>
                    <li>To protect and defend the rights or property of Daily Spin: Coin master city</li>
                    <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                    <li>To protect the personal safety of users of the Service or the public</li>
                    <li>To protect against legal liability</li>
                </ul>

                <h2>Security Of Data</h2>
                <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

                <h2>Service Providers</h2>
                <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
                <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

                <h2>Links To Other Sites</h2>
                <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>


                <h2>Changes To This Privacy Policy</h2>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

                <h2>Contact Us</h2>
                <p>If you have any questions about this Privacy Policy, please contact us:</p>
                <ul>
                    <li>By email: rbtechlabskaran@gmail.com</li>
                </ul>
            </main>
            <FooterLayout/>
        </div>
    );
}

export default PrivacyPolicy;
