import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './output.css';
import reportWebVitals from './reportWebVitals';
import RewardScreen from "./Screens/RewardScreen";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import HomeScreen from "./Screens/HomeScreen";
import {SpinContext} from "./Provider/SpinProvider";
import {fetchSpin} from "./Controller/ApiController";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
import OneSignal from "react-onesignal";
import Sitemap from "./Screens/Sitemap";

const router = createBrowserRouter([
    {
        path: "/",
        element: <HomeScreen/>
    },
    {
        path: "/rewards",
        element: <RewardScreen/>,
    },
    {
        path: "/privacy",
        element: <PrivacyPolicy/>,
    },
    {
        path: "/sitemap.xml",
        element: <Sitemap/>,
    }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));


function App(props) {
    const [spin, setSpin] = useState([]);
    const [isOnesignalInit, setonesignalInit] = React.useState(false);

    async function runOneSignal() {
        try {
            if (isOnesignalInit) {
                return;
            }
            await OneSignal.init({appId: '53695b5b-aa74-4dcf-a947-49b4acb38aca', allowLocalhostAsSecureOrigin: true,});
            await OneSignal.Slidedown.promptPush();
            setonesignalInit(true);
        } catch (e) {

        }
    }

    useEffect(() => {
        runOneSignal().then(r => {
        });
    }, []);

    const refreshSpin = async () => {
        let s = await fetchSpin();
        setSpin(s);
        return s;
    }

    return (
        <SpinContext.Provider value={{spin, refreshSpin}}>
            <RouterProvider router={router}/>
        </SpinContext.Provider>
    );
}

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
