import React, {useContext, useEffect, useState} from 'react';
import NavBar from "../Components/NavBar";
import ArtWork from '../assets/artwork.jpg';
import {Link, useLocation} from "react-router-dom";
import {SpinContext} from "../Provider/SpinProvider";
import {Helmet} from "react-helmet";


const appleUrl = "https://apps.apple.com/us/app/coin-master-spin-mania-links/id6477392095";
const androidUrl = "https://play.google.com/store/apps/details?id=com.coinMaster.spin_Mania";

function getOS() {
    var uA = navigator.userAgent || navigator.vendor || window.opera;
    if ((/iPad|iPhone|iPod/.test(uA) && !window.MSStream) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';

    var i, os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
    for (i = 0; i < os.length; i++) if (new RegExp(os[i], 'i').test(uA)) return os[i];
}

function RewardScreen(props) {
    const spinContext = useContext(SpinContext);

    let [spins, setSpins] = useState([]);
    let [data, setData] = useState({});
    let [status, setStatus] = useState("");
    let [date, setdate] = useState("");
    let [loading, setLoading] = useState(false);

    function formatDate(da) {
        let _date = new Date(parseInt(da) * 1000);
        var options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

        let _formatted = _date.toLocaleDateString("en-US", options);
        setdate(_formatted);
    }

    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    async function startTimer() {
        setLoading(true);
        let apple = ["ios", "mac"];
        let android = ["android"];
        let platform = getOS().toLowerCase();

        if (apple.includes(platform)) {
            await timeout(3000);
            setLoading(false);
            await window.open(appleUrl, "_self");
            return;
        }
        /// Installed Apple Device.
        if (android.includes(platform)) {
            await timeout(3000);
            setLoading(false);
            await window.open(androidUrl, "_self");
            return;
        }
        setLoading(false);
    }

    function useQuery() {
        const {search} = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();


    useEffect(() => {
        setStatus("Please wait your reward is loading")
        spinContext.refreshSpin().then((e) => {
            setSpins(e);
            console.log(query.get("flag") ?? "&flag=1");
            let p = e.filter(function (x) {
                if (x["rewardURL"] !== null && typeof x["rewardURL"] !== 'undefined' && x["rewardURL"] !== '') {
                    if (x["rewardURL"] === "code=" + query.get("code") + ("&flag=" + (query.get("flag") ?? "1"))) {
                        console.log(x["rewardURL"]);
                        return true
                    }
                }
                return false;
            });
            if (p.length > 0) {
                setStatus("");
                setData(p[0]);
                formatDate(p[0].date);
            } else {
                setStatus("No Reward with this code founded");
            }
        });

    }, []);

    return (
        <main>
            <Helmet>
                <link rel="preload" href={ArtWork} as="image"/>
                <title>{"Coin Master: Free Spins & Coins Links " + date + " Updated"}</title>
                <meta name="description" content={data.title + date + " Coin Master Reward"}/>
                <link rel="canonical" href={window.location.href}/>
            </Helmet>
            <Helmet>
                <meta property="og:title" content={data.title}/>
                <meta property="og:description" content={data.description}/>
                <meta property="og:image" content={ArtWork}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:type" content="website"/>
            </Helmet>
            <Helmet>
                <meta name="twitter:title" content={data.title}/>
                <meta name="twitter:description" content={data.description}/>
                <meta name="twitter:image" content={ArtWork}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <NavBar/>
            {

                status !== "" ? <div className={'h-96 flex justify-center w-full items-center flex-col'}>

                        <p className={'font-semibold text-gray-500'}> {status}  </p>
                        <Link to={'/'} className={'pt-10'}>Back to Home</Link>
                    </div> :
                    <div className={'flex flex-col md:px-10 md:py-10 px-5 py-5 lg:px-36'}>
                        <h1 aria-description={"Coin Master Reward "+ data.title}
                            className={'text-2xl md:text-3xl font-extrabold pb-1'}>{"Coin Master Reward " + data.title}
                        </h1>
                        <h1 aria-description={date + " Coin Master Reward"}
                            className={'text-sm md:text-xl font-extrabold pb-5 text-gray-600'}>{ date + " " }<span className={'text-red-500'}> Updated</span>
                        </h1>
                        <div className={"flex justify-center"}>
                            <div className={'flex items-center'}>
                                <img className={'rounded 2xl:self-center'} src={ArtWork}
                                     alt={`Coin Master Spin For ${date}`}/>
                            </div>
                        </div>
                        <div className={'flex-col md:flex-row flex justify-between pt-10 '}>
                            <div>
                                <h1 className={'text-sm md:text-xl font-extrabold'}>{data.title}</h1>
                                <p className={'text-sm  md:text-xl text-gray-500'}>{date}</p>
                            </div>
                            <div
                                className={`mt-3 md:mt-0 justify-center unselectable h-10 px-5 py-5 rounded cursor-pointer text-white text-center flex items-center ${loading ? 'bg-gray-500' : 'bg-gray-800'}`}>
                                <Link to={`cmlinkopener://www.coinmasterfreespin.in/${query.get("code")}`}
                                      onClick={async () => {
                                          if (loading) {

                                              return;
                                          }
                                          await startTimer();
                                      }}>
                                    <p className={'items-center self-center text-center justify-center'}> {
                                        !loading ? "Redeem Now" : "Loading"
                                    }</p>
                                </Link>
                            </div>

                        </div>

                        <div className={'pt-5'}>
                            {/*<h1 className={'text-2xl md:text-3xl font-extrabold'}>Description</h1>*/}
                            {/*<p className={'text-lg md:text-xl font-medium 2xl:font-normal text-gray-500 pt-3 md:pt-5'}>*/}
                            {/*    {data.description*/}
                            {/*    }*/}
                            {/*</p>*/}
                            <h1 className={'text-sm md:text-xl font-extrabold'}>{`Updated: ${date}: We added more Coin Master free spins links!`}</h1>
                            <p className={'text-sm md:text-xl  font-medium 2xl:font-normal text-gray-500 pt-3 md:pt-5'}>
                                Free spins and coin links significantly boost daily progress in Coin Master, a valuable
                                feature since purchasing spins can become expensive. Players who are budget-conscious
                                understandably seek to maximize these free opportunities to enhance their game
                                experience without extra cost.
                            </p>
                            <h1 className={'text-sm md:text-xl font-extrabold pt-5'}>Get More Free Spins and Coins in
                                Coin Master</h1>
                            <p className={'text-sm md:text-xl  font-medium 2xl:font-normal text-gray-500 pt-3 md:pt-5'}>Speed
                                up your gameplay and stay competitive in Coin Master with these tips to earn more
                                free spins and coins:</p>
                            <ol className={'text-sm md:text-xl font-medium 2xl:font-normal text-gray-500'}>
                                <li className={'pb-1.5'}><strong className={'text-black'}>Daily Links:</strong> Make it
                                    a routine to check for daily free spin and
                                    coin links. These links expire, so act fast to accumulate your resources.
                                </li>
                                <li className={'pb-1.5'}><strong className={'text-black'}>Invite
                                    Friends:</strong> Invite your Facebook friends to join Coin Master.
                                    You'll get 40 free spins for every friend who joins and links their Facebook
                                    account.
                                </li>
                                <li className={'pb-1.5'}><strong className={'text-black'}>Gift Spins:</strong> Send and
                                    receive free spins with friends. This can net
                                    you up to 100 extra spins if you maintain an active list of Coin Master friends.
                                </li>
                                <li className={'pb-1.5'}><strong className={'text-black'}>Reward Calendar:</strong> Log
                                    in daily to claim free spins and coins from
                                    the Reward Calendar.
                                </li>
                                <li className={'pb-1.5'}><strong className={'text-black'}>Social Media
                                    Follows:</strong> Keep tabs on Coin Master's Facebook and
                                    Twitter for updates and occasional free spins.
                                </li>
                                <li className={'pb-1.5'}><strong className={'text-black'}>Email Gifts:</strong> Sign up
                                    for email gifts to receive free spins. While
                                    you may get some extra emails, the free spins are often worth it.
                                </li>
                                <li className={'pb-1.5'}><strong className={'text-black'}>Watch Ads:</strong> Earn a few
                                    additional spins each day by watching video
                                    ads via the slot machine interface.
                                </li>
                                <li className={'pb-1.5'}><strong className={'text-black'}>Play Regularly:</strong> Just
                                    playing the game can earn you spins. Look out
                                    for three spin energy symbols in a row on the slot machine for a spin bonus.
                                </li>
                                <li className={'pb-1.5'}><strong className={'text-black'}>Upgrade Your
                                    Village:</strong> Building and upgrading your village can
                                    reward you with free spins, though it's a more challenging way to earn them.
                                </li>
                                <li className={'pb-1.5'}><strong className={'text-black'}>Participate in
                                    Events:</strong> Coin Master events are great opportunities
                                    to win loads of spins and coins. Keep an eye out for special event icons in your
                                    game interface.
                                </li>
                                <li className={'pb-1.5'}><strong className={'text-black'}>Hourly
                                    Spins:</strong> Remember, you get five free spins every hour. If your
                                    spin count is near the maximum of 50, use some to maximize what you gain over time.
                                </li>
                            </ol>

                            <h2 className={'text-sm md:text-xl font-extrabold pt-5'}>Frequently Asked Questions</h2>
                            <ul className={'text-sm md:text-xl font-medium 2xl:font-normal text-gray-500'}>
                                <li className={'pb-1.5'}><strong className={'text-black'}>Redeem Free
                                    Spins:</strong> Always use your mobile device where Coin Master
                                    is installed to redeem free spin links. Links typically expire after three days.
                                </li>
                                <li className={'pb-1.5'}><strong className={'text-black'}>Fix Link Errors:</strong> If a
                                    free spin link doesn't work, ensure Coin
                                    Master is installed or updated on your device. Sometimes links expire.
                                </li>
                                <li className={'pb-1.5'}><strong className={'text-black'}>Big Spin
                                    Rewards:</strong> Large free spin rewards (50-800 spins) usually
                                    occur during special events or are shared via social media. Stay active and engaged
                                    to maximize your chances.
                                </li>
                            </ul>
                        </div>
                    </div>
            }
        </main>
    );
}

export default RewardScreen;
