import React, {useContext, useEffect, useState} from 'react';
import NavBar from "../Components/NavBar";
import SpinList from "../Components/SpinList";
import FooterLayout from "../Components/FooterLayout";
import {SpinContext} from "../Provider/SpinProvider";
import HeroSection from "../Components/HearoSection";
import {Helmet} from "react-helmet";
import Logo from '../assets/logo.png';

import ArtWork from "../assets/artwork.png";



function HomeScreen(props) {
    const spinContext = useContext(SpinContext);

    let [spins, setSpins] = useState([]);
    const [date, setDate] = React.useState(new Date('2019-02-26 02:00:00'));
    const [showDatePicker, setDatePicker] = React.useState(false);

    function onChange(event) {
        let search = event.target.value;
        if (search === "") {
            setSpins(spinContext.spin);
            return;
        }

        let temp = [];
        for (let i = 0; i < spinContext.spin.length; i++) {
            let spin = spinContext.spin[i];
            let date = new Date(parseInt(spin.date) * 1000);
            date = date.toLocaleDateString("en-US");
            let dd = Date.parse(search);
            let parseDd = new Date(parseInt(dd));
            if (date.includes(parseDd.toLocaleDateString("en-US"))) {
                temp.push(spin);
                continue;
            }

            if (spin.title.toLowerCase().includes(search.toLowerCase())) {
                temp.push(spin);
            }
        }

        setSpins(temp);

    }


    useEffect(() => {

        spinContext.refreshSpin().then((e) => {
            setSpins(e);
        });

    }, []);

    let maxDate = new Date(parseInt(Date.now()));
    return (
        <div className={'relative'}>

            <NavBar/>
            <HeroSection/>
            <div className={'flex justify-center items-center'}>
                <div
                    className={'w-full md:w-1/2 mx-5 my-3 flex focus:outline-none focus:border focus:border-gray-700 focus:rounded border border-gray-300 rounded'}>
                    <input onClick={() => {
                        setDatePicker(!showDatePicker);
                    }} type={'date'}
                           onChange={onChange}
                           defaultValue={maxDate.toLocaleDateString("en-US")}
                           title={"Select Date"}
                        // readOnly={true}
                           max={maxDate.toLocaleDateString("en-US")}
                           className={' py-2 md:py-2 px-1 w-full'}
                    >
                    </input>
                </div>
            </div>
            <SpinList spins={spins}/>
            <FooterLayout/>
        </div>
    );
}

export default HomeScreen;
