import {BellAlertIcon, LinkIcon, NoSymbolIcon} from '@heroicons/react/20/solid'

import art from "../assets/artwork1.webp"
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import ArtWork from "../assets/artwork.png";
import React from "react";

const features = [
    {
        name: 'Daily Notifications.',
        description:
            'Players receive alerts every day to claim new rewards, ensuring they never miss out on free items.',
        icon: BellAlertIcon,
    },
    {
        name: 'Priority Links Update.',
        description: 'The links are updated promptly to provide the latest bonuses, keeping the rewards fresh and relevant.',
        icon: LinkIcon,
    },
    {
        name: 'Easy, Ad-Free Interface.',
        description: 'Accessing these links is straightforward and free from intrusive advertisements, enhancing user experience..',
        icon: NoSymbolIcon,
    },
]

export default function HeroSection() {
    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32">
            <Helmet>
                <link rel="preload" href={ArtWork} as="image"/>
                <meta property="og:title" content={"Coin Master Daily\n" +
                    "                                Rewards Links"}/>
                <meta property="og:description"
                      content={" Coin Master Daily Rewards Links offer players free spins, coins, and other bonuses\n" +
                          "                                through daily updated URLs. Available on the game's social media or community forums,\n" +
                          "                                these links help players progress without spending money."}/>
                <meta property="og:image" content={ArtWork}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:type" content="website"/>
            </Helmet>
            <Helmet>
                <meta property="og:title" content={"Coin Master Daily\n" +
                    "                                Rewards Links"} />
                <meta property="og:description" content={" Coin Master Daily Rewards Links offer players free spins, coins, and other bonuses\n" +
                    "                                through daily updated URLs. Available on the game's social media or community forums,\n" +
                    "                                these links help players progress without spending money."} />
                <meta name="twitter:image" content={ArtWork} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div
                    className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h1 className="text-base font-semibold leading-7 text-indigo-600">Coin Master</h1>
                            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Daily
                                Rewards Links</h2>
                            <p className="mt-6 text-lg leading-8 text-gray-600">

                                Coin Master Daily Rewards Links offer players free spins, coins, and other bonuses
                                through daily updated URLs. Available on the game's social media or community forums,
                                these links help players progress without spending money.

                            </p>
                            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                {features.map((feature) => (
                                    <div key={feature.name} className="relative pl-9">
                                        <dt className="inline font-semibold text-gray-900">
                                            <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                                                          aria-hidden="true"/>
                                            {feature.name}
                                        </dt>
                                        {' '}
                                        <dd className="inline">{feature.description}</dd>
                                    </div>
                                ))}

                            </dl>
                        </div>
                        <Link onClick={()=>{
                            const violation = document.getElementById("spins");
                            window.scrollTo({
                                top:violation.offsetTop,
                                behavior:"smooth"
                            });
                        }} to={"/#spins"} className={"flex justify-center font-bold text-gray-500"}>
                            Scroll Down for Links
                        </Link>
                    </div>
                    <img
                        src={art}
                        alt="Product screenshot"
                        className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                        width={2432}
                        height={1442}
                    />
                </div>
            </div>
        </div>
    )
}
