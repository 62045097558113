import React from 'react';

import Logo from '../assets/logo.png';
import {Link} from "react-router-dom";

function LogoComponent(props) {
    return (
        <Link to={"/"}>
            <div className={'py-3 px-5 cursor-pointer flex items-center'}>
                <img src={Logo} style={{height:"60px",width:"60px"}} alt={"logo"} className={'bg-white flex'}/>
                <p className={'flex font-bold text-black text-xl'}>Coin Master Daily Reward</p>

            </div>
        </Link>
    );
}

export default LogoComponent;
