import axios from 'axios';
import useSWR from "swr";


export async function fetchSpin() {

    try {

        let spins = await axios.get("https://supercellapi.miracocopepsi.com/api/spins", {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        });
        return spins.data;
    } catch (e) {

        console.log(e);
        return [];
    }

}

