import React from 'react';
import {Link} from "react-router-dom";
const appleUrl = "https://apps.apple.com/us/app/coin-master-spin-mania-links/id6477392095";
const androidUrl = "https://play.google.com/store/apps/details?id=com.coinMaster.spinMania";
function FooterLayout(props) {
    return (
       <div className={'pt-5'}>
           <div className={'bg-gray-200'} style={{height:1}}/>
           <div className={'flex justify-center '}>
               <ul className={'flex'}>
                   <li className={'px-3 py-3'}>
                       <Link to={androidUrl} target={"_blank"}>
                           <p className={'font-medium text-gray-400'}>Android App</p>
                       </Link>
                   </li>
                   <li className={'px-3 py-3'} >
                       <Link to={appleUrl} target={"_blank"}>
                           <p className={'font-medium text-gray-400'}>Ios App</p>
                       </Link>
                   </li>
                   <li className={'px-3 py-3'} >
                       <Link to={"/privacy"} target={"_self"}>
                           <p className={'font-medium text-gray-400'}>Privacy Policy</p>
                       </Link>
                   </li>

                   <li className={'px-3 py-3'} >
                       <Link to={"/sitemap.xml"} target={"_self"}>
                           <p className={'font-medium text-gray-400'}>Site Map</p>
                       </Link>
                   </li>
               </ul>
           </div>
           <div className={'flex justify-center pb-5 font-normal text-gray-400'}>
              <p>Coin master Free Spin</p>
           </div>
       </div>
    );
}

export default FooterLayout;
